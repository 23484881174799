<template>
  <div class="register-container">
    <div class="bgcolor"></div>
    <div class="login-label">
      <div class="title-container">
        <h3 class="title">江苏省工程建设地方标准编制管理系统</h3>
        <div class="first">{{ kayVal == 'forget' ? '忘记密码' : '用户注册' }}</div>
      </div>
      <div class="shuru">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="register-form" label-width="100px">
     
          <div v-if="kayVal != 'forget'">
            <el-form-item label="" prop="name">
              <el-input v-model="ruleForm.name" placeholder="单位名称"></el-input>
            </el-form-item>
            <div class="inlines">
              <el-form-item prop="scn">
                <el-input v-model="ruleForm.scn" placeholder="统一社会信用代码"></el-input>
              </el-form-item>
              <el-form-item prop="category_id" class="unitcore">
                <el-select v-model="ruleForm.category_id" placeholder="单位类型">
                  <el-option v-for="item in entType" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="inlines">
              <el-form-item label="" prop="license">
                <el-input v-model="ruleForm.license" style="display: none;"></el-input>
                <upload-file ref="uploadimg" :fileType="fileType" @reserve-data="getFileData"></upload-file>
              </el-form-item>
            </div>
            <div class="inlines">
              <el-form-item prop="city1_id">
                <el-select v-model="ruleForm.city1_id" placeholder="注册所在省、直辖市或自治区" @change="cityChange">
                  <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="city2_id" class="unitcore">
                <el-select v-model="ruleForm.city2_id" placeholder="注册所在市">
                  <el-option v-for="item in countyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="inlines">
              <el-form-item prop="contact">
                <el-input v-model="ruleForm.contact" placeholder="联系人姓名"></el-input>
              </el-form-item>
              <el-form-item prop="telephone">
                <el-input v-model="ruleForm.telephone" placeholder="手机"></el-input>
              </el-form-item>
              <el-form-item prop="email">
                <el-input v-model="ruleForm.email" placeholder="邮箱"></el-input>
              </el-form-item>
            </div>
          </div>

          <div v-if="kayVal == 'forget'">
            <el-form-item prop="username" label="账号">
              <el-input v-model="ruleForm.username" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item prop="telephone" label="电话">
              <el-input placeholder="请输入电话" v-model="ruleForm.telephone" clearable></el-input>
            </el-form-item>
            <el-form-item prop="email" label="邮箱">
              <el-input placeholder="请输入邮箱" v-model="ruleForm.email" clearable></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="imageCode">
              <el-input placeholder="请输入验证码" v-model="ruleForm.imageCode" @keyup.native.enter="user_login">
                <template slot="append">
                  <div class="login-code" @click="refreshCode">
                    <canvas id="canvas" height="40" width="116"></canvas>
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </div>
          <el-button class="regitserbtn" type="primary" @click="submitForm('ruleForm')">
            {{ kayVal == 'forget' ? '提交密码重置申请' : '注册' }}
          </el-button>
        </el-form>
        <div class="logcont2">
          <!-- <div class="forget">忘记密码</div> -->

          <div class="forget">
            已有账号，
            <router-link :to="{ path: '/login', query: { default: default_password } }">
              <span style="color: #3082e2;text-decoration: none;">点击登录</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="checkVisible" custom-class="news_dialog" :close-on-click-modal="false" title="注册详情">
        <div>{{ tip_msg }}</div>
        <div class="text_tip">提醒:进入系统后需要修改密码！</div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="infoCancle()">确 定</el-button>
          <el-button @click="infoCancle()">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { register,  } from "../../api/user";
import JiangSu from "@assets/jiangsu_se.json";
import { register_putong, userRegisterAdd } from "@api/user";
import { cityOne, countryData, registerEnt } from "@api/enterprise";
import uploadFile from "../views/EngeryEvaluation/centerMange/technicalReview/components/uploadFile.vue"
import { validateMobile } from '../utils/config'
export default {
  components: { uploadFile },
  data() {
    var validatePass2 = (rule, value, callback) => {
      // console.log("rule", rule);
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      fileType: 'all',
      checkVisible: false,
      tip_msg: "",
      searchText: '', // 输入框内容
      searchResult: [], // 搜索结果
      labelPosition: "top",
      cityOptions: JiangSu.city,
      type: [
        {
          type: "array",
          required: true,
          message: "请选择角色",
          trigger: "change",
        },
      ],
      ruleForm: {
        // username: "",
        // password: "",
        // checkPass: "",
        name: "",
        scn: "",
        category_id: "", //单位类型
        license: "", //营业执照扫描
        city1_id: "",
        city2_id: "",
        contact: "", //联系人
        telephone: "", //电话
        email: "", //邮箱
        imageCode: ""
      },
      citys: [],
      countyList: [],
      entType: [
        {
          value: 1,
          label: '建设单位'
        },
        {
          value: 2,
          label: '事业单位'
        },
        {
          value: 3,
          label: '勘察设计单位'
        },
        {
          value: 4,
          label: '施工单位'
        },
        {
          value: 5,
          label: '监理单位'
        },
        {
          value: 6,
          label: '技术咨询单位'
        },
        {
          value: 7,
          label: '构件生产厂家'
        },
        {
          value: 8,
          label: '物业管理单位'
        }
      ],
      rules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请填写单位名称" },
        ],
        scn: [
          {
            required: true, message: "请输入统一社会信用代码"
          }
        ],
        category_id: [
          { required: true, message: "请选择单位类型", trigger: 'change' }
        ],
        license: [
          { required: true, message: "请上传营业执照", trigger: 'change' }
        ],
        city1_id: [{ required: true, message: "请选择省、直辖市或自治区", trigger: 'change' }],
        city2_id: [{ required: true, message: "请选择市", trigger: 'change' }],
        contact: [{ required: true, message: "请输入联系人", trigger: 'blur' }], //联系人
        telephone: [{ required: true, message: "请输入电话", trigger: 'blur' }, { validator: validateMobile.bind(this), trigger: 'blur' },
        ], //手机号码
        email: [{ required: true, message: "请输入邮箱", trigger: 'blur' }], //邮箱        
        imageCode: [{
          required: true, message: "请输入验证码"
        }]
      },
      verifyCode: '',
      kayVal: '',
      default_password: ''
    };
  },
  mounted() {
    this.kayVal = this.$route.query.key
    this.getCityOne()
    if (this.kayVal === 'forget') {
      this.$nextTick(function () {
        this.createCanvas();
      }, 1000)
    }
  },
  methods: {
    // 输入框内容改变时的处理函数
    handleSearch() {
      if (!this.searchText) { // 如果输入框为空，则清空搜索结果
        this.searchResult = [];
        return;
      }
      // 向后端发送请求，获取相关数据，然后将搜索结果赋值给 searchResult
      this.$http.get('/api/search', { params: { keyword: this.searchText } }).then(res => {
        this.searchResult = res.data;
      }).catch(err => {
        console.log(err);
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.avatar = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //获取文件的方法
    getFileData(obj) {
      // console.log(obj, '打印文件的对象')
      this.ruleForm.license = obj.photo
    },
    getCityOne() {
      cityOne()
        .then((res) => {
          // console.log(res, '获取市区相关的数据');
          this.citys = res.data
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    //市区筛选
    cityChange() {
      this.getDistrict(this.ruleForm.city1_id)
      this.ruleForm.city2_id = ""
    },
    getDistrict(pid) {
      let params = {
        parent_id: pid
      }
      countryData(params)
        .then((res) => {
          console.log(res, '获取市区相关的数据');
          this.countyList = res.data
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log('token', this.$store.state.token)
          let params = {
            token: this.$store.state.token,
            ...this.ruleForm
          };
          // console.log(params, '入参打印')
          if (this.kayVal === 'forget') {
            //忘记密码
            userRegisterAdd(params)
              .then((res) => {
                console.log(res);
                this.$message.success(res.message);
                if (res.code === 200) {
                  this.$router.push({
                    path: "/login",
                  });
                }
              })
              .catch((err) => {
                //console.log(err)
                this.$message.error(err.message);
              });
          } else {
            //注册
            registerEnt(params)
              .then((res) => {
                console.log(res);
                this.$message.success("注册成功");
                this.checkVisible = true;
                this.tip_msg = res.message;
                this.default_password = res.data
                // this.$router.push({ path: "/login" });
              })
              .catch((err) => {
                //console.log(err)
                this.$message.error(err.message);
              });
          }

        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    returnLogin() {
      // console.log(this.default_password, '打印传值')
      this.$router.push({
        name: 'login',
        params: {
          default_password: this.default_password
        }
      })

    },
    infoCancle() {
      this.checkVisible = false
      this.$refs.ruleForm.resetFields() //清空表单数据
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    refreshCode() {
      this.verifyCode = this.createCanvas();
    },
    rn(min, max) {
      return parseInt((Math.random() * (max - min) + min).toString());
    },
    rc(min, max) {
      let r = this.rn(min, max);
      let g = this.rn(min, max);
      let b = this.rn(min, max);
      return `rgb(${r},${g},${b})`;
    },
    createCanvas() {
      let ctx = document.getElementById('canvas').getContext('2d');
      ctx.fillStyle = this.rc(180, 230);
      ctx.fillRect(0, 0, 116, 40);
      let pool = '0123456789';
      let code = '';
      for (let i = 0; i < 4; i++) {
        let c = pool[this.rn(0, pool.length)];
        let fs = this.rn(24, 30);
        let deg = this.rn(-30, 30);
        ctx.font = fs + 'px sans-serif';
        ctx.textBaseline = 'top';
        ctx.fillStyle = this.rc(0, 200);
        ctx.save();
        ctx.translate(30 * i + 10, this.rn(12, 20));
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(c, 0, 0);
        ctx.restore();
        code += c;
      }
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(this.rn(0, 116), this.rn(0, 40));
        ctx.lineTo(this.rn(0, 116), this.rn(0, 40));
        ctx.strokeStyle = this.rc(0, 160);
        ctx.closePath();
        ctx.stroke();
      }
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(this.rn(0, 116), this.rn(0, 40), 1, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = this.rc(0, 200);
        ctx.fill();
      }
      return code;
    },
  },
};
</script>
<style scoped>
/deep/.el-select {
  width: 100%;
}

/deep/.el-form-item__label {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.register-container .login-code {
  height: 38px;
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  padding-left: 0px;
  padding-right: 0px;
}

/deep/ .shuru .el-form-item__content {
  margin-left: 0px !important;
}

.register-container .text_tip {
  color: #f44336;
  padding-top: 20px;
}

/deep/ .shuru .el-form-item__label {
  text-align: left;
}
</style>
<style lang="scss" scoped>
$bg: #ffffff;
$dark_gray: #889aa4;
$light_gray: #eee;

.bgcolor {
  width: 50%;
  height: 100vh;
  background-color: #3082e2;
  // background-image: url("https://cloud.cdmp.tech/ccf0fa5c5dcfcc8b152e36068b699f00.png");
  // background-size: cover;
}

.shuru {
  margin: 0 auto;
  width: 360px;
  height: 360px;
  border-radius: 20px;

  // padding: 70px;
  .inlines {
    display: flex;

    .unitcore {
      margin-left: 11px;
    }
  }
}

.logcont2 {
  margin-top: 27px;

  // width: 400px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  .forget {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
}

.login-label {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background-size: 100% 100%;

  .register-form {
    position: relative;
    // width: 520px;
    // max-width: 100%;
    // padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
}

.title-container {
  position: relative;

  .title {
    font-size: 36px;
    color: #333333;
    margin: 0px auto 20px auto;
    text-align: center;
    font-weight: bold;
  }
}

.regitserbtn {
  width: 100%;
  background: #3082e2;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.first {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin: 40px 0;
  text-align: center;
}
</style>
